import React from "react";
import { Element } from "react-scroll";
// import { ReactComponent as ArrowReact } from "../../assets/svg/ArrowRight.svg";

export default function Blog() {
  return (
    <>
      <Element id="blog" name="blog">
        <div className="mx-6 sm:mx-20 sm:my-16 " data-aos="zoom-out-up">
          <div className="flex flex-wrap">
            <div
              className="sm:w-[50%]"
              data-aos="fade-in"
              data-aos-duration="1000"
            >
              <h1 className="sm:text-5xl text-2xl font-semibold leading-[1.2]">
                Digital Marketing & SEO ServicesThat Grow Traffic & increase
                Revenue
              </h1>
            </div>
            <div
              className="sm:w-[50%] mt-5 sm:mt-0"
              data-aos="fade-in"
              data-aos-duration="1000"
            >
              <p className="text-xl leading-[1.5] font-semibold text-gray-500">
                we are the top digital ,arketing agency for branding corp . We
                offer a full range of services to help clients improve their
                search engine ranking and drive more traffic to their websites.
              </p>
              <buttom className="mt-8 font-bold rounded-full btn btn-outline btn-wide sm:btn-md btn-neutral px-14">
                see more
              </buttom>
            </div>
          </div>
          <div>
            <section class="text-gray-500 body-font">
              <div class="container sm:px-5 py-16 mx-auto">
                <div class="flex flex-wrap -m-4">
                  <div
                    class="p-2 lg:w-1/3"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <div class="h-full bg-gray-100 bg-opacity-75 px-4 py-10 rounded-3xl overflow-hidden text-center relative">
                      <h2 className="flex justify-between">
                        <span className="indicator-item indicator-middle indicator-start badge badge-sm badge-secondary"></span>
                        <span className="text-sm text-gray-400">
                          {" "}
                          5 min read
                        </span>
                      </h2>
                      <div className="mt-3 bg-white rounded-lg h-44 artboard artboard-horizontal size-92"></div>
                      <h1 class="title-font sm:text-2xl text-xl text-left font-bold text-gray-900 my-5 ">
                        How a Digital Marketing Agency Can Enhance Your Business
                      </h1>
                      <div className="flex flex-wrap items-end">
                        <p class="leading-relaxed text-left text-sm me-6">
                          With the present fast-paced, ever-changing world of
                          digital, staying ahead of the competition requires
                          more than just having an online presence. Business
                          houses are always looking for new ways to appeal to
                          their targeted audience and create an influence
                          everyone would remember. In this regard, digital
                          marketing agencies play the most important role in
                          getting it done. The well-rounded strategy we have at
                          BuzingBee- a strategy to understand growth, improve
                          brand visibility, and drive sales digitally-is
                          something quite fundamental.
                        </p>
                        <buttom className="btn btn-outline mt-4 sm:mt-4 sm:p-4 flex sm:block text-white btn-neutral rounded-full sm:px-8  sm:btn-md px-[41%] btn-active">
                          More
                          {/* <ArrowReact /> */}
                        </buttom>
                      </div>
                    </div>
                  </div>
                  <div
                    class="p-2 lg:w-1/3"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <div class="h-full bg-gray-100 bg-opacity-75 px-4 py-10 rounded-3xl overflow-hidden text-center relative">
                      <h2 className="flex justify-between">
                        <span className="indicator-item indicator-middle indicator-start badge badge-sm badge-info"></span>
                        <span className="text-sm text-gray-400">
                          {" "}
                          5 min read
                        </span>
                      </h2>
                      <div className="mt-3 bg-white rounded-lg h-44 artboard artboard-horizontal size-92"></div>
                      <h1 class="title-font sm:text-2xl text-xl text-left font-bold text-gray-900 my-5 ">
                        How BuzingBee Helped to Boost Your Business Rapidly
                      </h1>
                      <div className="flex flex-wrap items-end">
                        <p class="leading-relaxed text-left text-sm me-6">
                          In a world dominated by digital transformation, having
                          a solid online presence can make or break your
                          business. At BuzingBee, we pride ourselves on
                          delivering tailor-made solutions that drive growth and
                          create impact. This is the story of how we helped
                          businesses across different industries skyrocket their
                          success through innovative digital marketing
                          strategies.
                        </p>
                        <buttom className="btn btn-outline mt-4 sm:mt-4 sm:p-4 flex sm:block text-white btn-neutral rounded-full sm:px-8  sm:btn-md px-[41%] btn-active">
                          More
                          {/* <ArrowReact /> */}
                        </buttom>
                      </div>
                    </div>
                  </div>
                  <div
                    class="p-2 lg:w-1/3"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <div class="h-full bg-gray-100 bg-opacity-75 px-4 py-10 rounded-3xl overflow-hidden text-center relative">
                      <h2 className="flex justify-between">
                        <span className="indicator-item indicator-middle indicator-start badge badge-sm badge-warning"></span>
                        <span className="text-sm text-gray-400">
                          {" "}
                          5 min read
                        </span>
                      </h2>
                      <div className="mt-3 bg-white rounded-lg h-44 artboard artboard-horizontal size-92"></div>
                      <h1 class="title-font sm:text-2xl text-xl text-left font-bold text-gray-900 my-5 ">
                        How Can Small Businesses Compete with Larger Brands in
                        Digital Marketing?
                      </h1>
                      <div className="flex flex-wrap items-end">
                        <p class="leading-relaxed text-left text-sm me-6">
                          In today’s digital era, small businesses face tough
                          competition from larger brands with bigger budgets and
                          wider reach. However, the beauty of digital marketing
                          is that it levels the playing field, allowing small
                          businesses to compete effectively without needing a
                          massive budget. Here's how small businesses can
                          compete with larger brands in the digital marketing
                          space.
                        </p>
                        <buttom className="btn btn-outline mt-4 sm:mt-4 sm:p-4 flex sm:block text-white btn-neutral rounded-full sm:px-8  sm:btn-md px-[41%] btn-active">
                          More
                          {/* <ArrowReact /> */}
                        </buttom>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </Element>
    </>
  );
}
